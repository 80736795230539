import { withDependencies } from '@wix/thunderbolt-ioc'
import { ViewerModelSym, ViewerModel } from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { IComponentPropsExtender } from 'feature-components'

const paypalButtonFactory = (
	viewerModel: ViewerModel,
	sessionManager: ISessionManager
): IComponentPropsExtender<{
	metaSiteId: string
	userId: string
	visitorId: string | undefined
}> => {
	return {
		componentTypes: ['PayPalButton'],
		getExtendedProps: () => ({
			metaSiteId: viewerModel.site.metaSiteId,
			userId: viewerModel.site.userId,
			visitorId: sessionManager.getVisitorId(),
		}),
	}
}

export const PaypalButton = withDependencies([ViewerModelSym, SessionManagerSymbol], paypalButtonFactory)
